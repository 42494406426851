<div class="container-12 pt-24px pl-20px pr-20px control-bar">
  <div class="row align-items-center h-34px">
    <div class="col left" *ngIf="showleftArrow">
      <div class="pointer-section h-34px w-34px" (click)="goTo(leftArrowPath)">
        <img src="../../../../../../assets/icons/left-arrow-icon.svg" alt="">
      </div>
    </div>
    <div class="col right" *ngIf="showcross">
      <div class="pointer-section float-end h-34px w-34px" (click)="goTo(crossPath)">
        <img src="../../../../../../assets/icons/x-icon.svg" alt="">
      </div>
    </div>
  </div>
</div>