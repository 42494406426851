<div id="bottom-modal">
  <div class="bottom-modal-clickable-section" (click)="close(false)"></div>
  <div class="bottom-modal" (mouseenter)="mouseInDiv = true" (mouseleave)="mouseInDiv = false">
    <div class="container-12">
      <div class="row">
        <div class="col right">
          <img src="../../../../../../assets/icons/x-icon.svg" (click)="close(true)" alt="">
        </div>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>