import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '../../../models/product';
import { User } from '../../../models/user';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() product?: Product = new Product()
  @Input() user?: User = new User();

  constructor(private route: Router) { }

  ngOnInit() {
  }

  goTo(route: any) {
    this.route.navigateByUrl(route)
  } 

}
