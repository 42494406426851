<div class="product-card">
  <div class="container-12">
    <div class="row align-items-center">
      <div class="col-3">
        <img [src]="product?.image" class="rounded-product-image" alt="">
      </div>
      <div class="col">
        <div class="row">
          <div class="col">
            <strong class="f-14px">
              {{product?.title}}
            </strong>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="cost-sect">
              <span class="f-purple f-12px">
                {{product?.cost}} pontos
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <button class="btn-default-white-orange" (click)="goTo(product?.path)">Detalhes</button>
      </div>
    </div>
  </div>
</div>