import { Inject, Injectable } from '@angular/core';
import { WINDOW } from './window.token';
import { TenantConfiguration } from '../shared/models/tenantConfiguration.interface';
import { TenantService } from './tenant.service';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  tenantConfigurations!: TenantConfiguration;

  constructor(@Inject(WINDOW) private window: Window, private tenantService: TenantService) { }
  init() {
    let domain = this.window.location.host;
    return this.tenantService.getTenantConfigurationsByDomain(domain).pipe(tap((config) => (this.tenantConfigurations = config)));
  }
}