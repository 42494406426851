export class Recyclable {
    name!: string;
    acceptableLocale?: string;
    icon!: string;
    pointsPerProduct!: number;
    unityType!: UnityType;
    recyclableInstructionsTitle?: string;
    recyclableInstructions?: string;
    productVariationsTitle?: string;
    productVariations: string[] = [];

    [key: string]: any;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export enum UnityType {
    KG = 'KG',
    UNIDADE = 'UNIDADE'
}

export enum RecycleModalType {
    INSTRUCTIONS = 'INSTRUCTIONS',
    TYPE_LIST = 'TYPE_LIST'
}