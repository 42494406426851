import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormaterService {

  constructor() { }

  removerSpecials(texto:string) {
    texto = texto.replace(/[ÀÁÂÃÄÅ]/, "A");
    texto = texto.replace(/[àáâãäå]/, "a");
    texto = texto.replace(/[ÈÉÊË]/, "E");
    texto = texto.replace(/[èéêë]/, "e");
    texto = texto.replace(/[ÒÓÔÕÖ]/, "O");
    texto = texto.replace(/[òóôõö]/, "o");
    texto = texto.replace(/[Ç]/, "C");
    texto = texto.replace(/[ç]/, "c");
    
    return texto.replace(/[^a-z0-9]/gi, '');
  }

}
