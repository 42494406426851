import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormaterService } from 'src/app/services/formater/formater.service';

@Component({
  selector: 'app-search-options-field',
  templateUrl: './search-options-field.component.html'
})
export class SearchOptionsFieldComponent {

  @Input() fieldName: string = 'blank-input-nane';
  @Input() fieldId?: string;
  @Input() fieldNgModel: any = '';
  @Input() fieldLabel?: string;
  @Input() fieldMask?: string;
  @Input() fieldDropMask: boolean = false;
  @Input() fieldHaveMask: any = '';
  @Input() isRequired: boolean = false;
  @Input() fieldOptions: any[] = []
  @Input() keepedOptions: any[] = []

  @Output() value = new EventEmitter<any>();
  @Output() needOptions = new EventEmitter<any>();

  showOptions = false
  mouseInDiv = false

  constructor(
    private formaterService: FormaterService
  ) {}

  searchOption(searchText:any) {
    const searchTextValue = searchText.target.value
    let newArr = [];
    if(searchTextValue.length > 0) {
      for(let option of this.keepedOptions) {
        const optionLabel:string = option.label.toUpperCase().trim()
        const formatedOptionLabel:string = this.formaterService.removerSpecials(optionLabel)
        const formatedSearchTextValue:string = this.formaterService.removerSpecials(searchTextValue.toUpperCase().trim())
        if(formatedOptionLabel.match(formatedSearchTextValue)) {
          newArr.push(option)
        }
      }
    } else {
      this.needOptions.emit(true)
      this.value.emit('');
      this.fieldNgModel = '';
    }
    this.fieldOptions = newArr
  }

  changeInputFocus(status: boolean): void {

    if(status) {
      this.showOptions = true
    }

    if (!status && !this.mouseInDiv) {
      this.fieldNgModel = '';
      this.showOptions = false;
      this.value.emit('');
    }

  }

  selectOption(value:any) {
    this.fieldNgModel = value.label;
    this.value.emit(value);
    this.showOptions = false;
  }
}
