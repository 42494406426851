import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'soma-text-field',
  templateUrl: './text-field.component.html'
})
export class TextFieldComponent {
  @Input() fieldName: string = 'blank-input-nane';
  @Input() fieldId?: string;
  @Input() fieldNgModel: string = '';
  @Input() fieldLabel?: string;
  @Input() fieldType!: string;
  @Input() fieldMask?: string;
  @Input() fieldDropMask: boolean = false;
  @Input() fieldHaveMask: any = '';
  @Input() isRequired: boolean = false;
  @Input() isPassword?: boolean = false;
  @Input() showPassword?: boolean
  @Input() isDisabled: boolean = false
  @Input() isPhoneFiled:boolean = false

  @Output() value = new EventEmitter<any>();
  

  enviarValor(disabledValue:string = '') {
    if(!this.isDisabled) {
      this.value.emit(this.fieldNgModel)
    } else {
      this.value.emit(disabledValue)
    }
  }

}
