import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Output() selectedItem = new EventEmitter<boolean>();
  @Input() iconUrl: string = '';
  @Input() userName!: string;

  constructor(private router: Router) {
  }

  selectItem(item: any) {
    this.selectedItem.emit(item);
    item.isActive = true;
  }

  goTo(event: any) {
    if (event.event === 'route') {
      console.log(event);
      this.router.navigateByUrl(event.path);
    }
  }

  logout() {
    sessionStorage.clear();
    window.location.reload();
  }
}
