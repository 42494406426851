<div class="form-group">
  <input *ngIf="!isPhoneFiled" [disabled]="isDisabled" autocomplete="off" mask="{{fieldMask}}" [dropSpecialCharacters]="fieldDropMask" #textField="ngModel" [type]="fieldType" [name]="fieldName" class="soma-filed" [ngClass]="{'passowd-field': showPassword === false}" [id]="fieldId" [required]="isRequired" (input)="enviarValor()" [(ngModel)]="fieldNgModel">
  <input *ngIf="isPhoneFiled" prefix="+55 " [disabled]="isDisabled" autocomplete="off" mask="{{fieldMask}}" [dropSpecialCharacters]="fieldDropMask" #textField="ngModel" [type]="fieldType" [name]="fieldName" class="soma-filed" [ngClass]="{'passowd-field': showPassword === false}" [id]="fieldId" [required]="isRequired" (input)="enviarValor()" [(ngModel)]="fieldNgModel">
  <label [for]="fieldId">{{fieldLabel}}</label>
  <div class="filed-icon-sect" *ngIf="isPassword && !isDisabled">
    <img src="../../../../../../../assets/icons/eye-on-icon.svg" *ngIf="!showPassword" class="filed-icon" alt="" (click)="showPassword = true">
    <img src="../../../../../../../assets/icons/eye-off-icon.svg" *ngIf="showPassword" class="filed-icon" alt="" (click)="showPassword = false">
  </div>
  <div class="filed-icon-sect" *ngIf="isDisabled">
    <img src="../../../../../../../assets/icons/edit-pen.svg" class="filed-icon" alt="" (click)="enviarValor(fieldId)">
  </div>
</div>