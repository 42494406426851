<div class="half-card">
  <div class="bg-sect" [ngStyle]="{'background-image': 'url(' + cardDetail?.background + ')'}"></div>
  <div class="content-sect">
    <div class="container">
      <div class="row pt-16px">
        <div class="col lh-16px">
          <strong class="f-purple f-14px">{{cardDetail?.title}}</strong>
          <br>
          <span class="f-gray f-12px">{{cardDetail?.detail}}</span>
        </div>
      </div>
      <div class="row pt-8px pb-16px align-items-center">
        <div class="col">
          <span class="f-orange f-14px">
            <img src="../../../../../../assets/icons/orange-right-icon.svg" class="h-22px" alt=""> LEIA MAIS
          </span>
        </div>
      </div>
    </div>
  </div>
</div>