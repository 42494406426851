<router-outlet></router-outlet>
@if(showMenu){
<div>
    <app-header [iconUrl]="tenantConfiguration.iconUrl"></app-header>
</div>
}

@if(showScan){
<div class="row pt-56px">
    <div class="col-12 cards-wrapper">
        <div class="dash-card text-center" (click)="goToScan()">
            <div class="square">
                <i class="bi bi-qr-code-scan icon"></i>
            </div>
            <p class="m-0 f-14px f-neutral-4 pt-24px pb-24px">Escaneie o código para entregar a recompensa</p>
            <button class="btn btn-default-primary">Escanear código QR</button>
        </div>
    </div>
</div>
}