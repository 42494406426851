import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UnityType } from 'src/app/shared/models/recyclable';

@Component({
  selector: 'app-calculator-field',
  templateUrl: './calculator-field.component.html',
  styleUrls: ['./calculator-field.component.scss']
})
export class CalculatorFieldComponent implements OnInit {
  @Input() unity = UnityType.KG
  @Output() value = new EventEmitter<any>();

  tempValue:any = 0

  constructor() { }

  ngOnInit() {
  }

  sendValue() {    
    this.value.emit(parseFloat(this.tempValue))
  }

}
