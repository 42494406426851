<div (mouseenter)="mouseInDiv = true" (mouseleave)="mouseInDiv = false">
  <div class="form-group">
    <input autocomplete="off" mask="{{fieldMask}}" [dropSpecialCharacters]="fieldDropMask" #textField="ngModel" [name]="fieldName" class="soma-filed" [id]="fieldId" [required]="isRequired" [ngClass]="{'semi-filed': showOptions}" (input)="searchOption($event)" [(ngModel)]="fieldNgModel" (focusin)="changeInputFocus(true)" (focusout)="changeInputFocus(false)">
    <label [for]="fieldId">{{fieldLabel}}</label>
    <div class="filed-icon-sect">
      <img src="../../../../../../../assets/icons/select-down-arrow.svg" *ngIf="!showOptions" class="filed-icon" alt="" (click)="showOptions = true">
      <img src="../../../../../../../assets/icons/select-down-arrow.svg" *ngIf="showOptions" class="filed-icon rotateimg180" alt="" (click)="showOptions = false">
    </div>
  </div>
  <div class="options-sect" *ngIf="showOptions">
    <div class="row w-100 option-row" *ngFor="let item of fieldOptions" (click)="selectOption(item); value.emit(item)">
      <div class="col">
        {{item.label}}
      </div>
    </div>
  </div>
</div>