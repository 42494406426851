<div class="transition-card">
  <div class="row">
    <div class="col lh-16px">
      <span class="f-gray f-10px">
        {{line?.label}}
      </span>
      <br>
      <strong class="f-14px">{{line?.description}}</strong>
    </div>
  </div>
</div>