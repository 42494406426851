import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItems } from './menu-items';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss']
})
export class FooterMenuComponent implements OnInit {

  @Output() showSideBar = new EventEmitter<boolean>()

  menuItem = new MenuItems()
  menuList: any[] = []

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe(() => {
      this.checkActiveMenu()
    })
  }

  ngOnInit() {
    this.setMenu()
  }

  setMenu() {
    this.menuList = [
      this.menuItem.menuModel(
        'dashboard/my-points',
        'assets/icons/coins-menu-icon.svg',
        'assets/icons/coins-menu-orange-icon.svg',
        'Pontos',
        'route',
        false
      ),
      this.menuItem.menuModel(
        'dashboard/my-card',
        'assets/icons/card-menu-icon.svg',
        'assets/icons/card-menu-orange-icon.svg',
        'Cartão',
        'route',
        false
      ),
      this.menuItem.menuModel(
        'dashboard/today',
        'assets/icons/calendar-menu-icon.svg',
        'assets/icons/calendar-menu-orange-icon.svg',
        'Início',
        'route',
        false
      ),
      this.menuItem.menuModel(
        'dashboard/rewards',
        'assets/icons/medal-menu-icon.svg',
        'assets/icons/medal-menu-orange-icon.svg',
        'Recompensas',
        'route',
        false
      ),
      this.menuItem.menuModel(
        'menu',
        'assets/icons/hamburger-icon.svg',
        '',
        'Menu',
        'event',
        false
      ),
    ]

    this.checkActiveMenu()
  }

  checkActiveMenu() {
    for (let menu of this.menuList) {
      menu.status = false

      if (window.location.href.includes(menu.path)) {
        menu.status = true
      }
    }
  }

  goTo(event: any) {
    if (event.event === 'route') {
      this.router.navigateByUrl(event.path)
    } else {
      this.showSideBar.emit(true)
    }
  }

}
