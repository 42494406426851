<div class="footer-menu">
  <div class="container-12">
    <div class="row align-items-center h-40px mt-8px">
      <div class="col center" *ngFor="let menuItem of menuList" (click)="goTo(menuItem)">
        <img class="menu-icon-size mb-4px" [src]="menuItem.icon" *ngIf="!menuItem.status" alt="">
        <img class="active-menu-icon-size" [src]="menuItem.activeIcon" *ngIf="menuItem.status" alt="">
      </div>
    </div>
    <div class="row align-items-sm-start">
      <div class="col center" *ngFor="let menuItem of menuList" (click)="goTo(menuItem)">
        <span class="f-10px f-gray" [ngClass]="{'f-orange': menuItem.status}">
          {{menuItem.label}}
        </span>
      </div>
    </div>
  </div>
</div>