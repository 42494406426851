import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-soma-check-box',
  templateUrl: './soma-check-box.component.html',
  styleUrls: ['./soma-check-box.component.scss']
})
export class SomaCheckBoxComponent implements OnInit {

  @Input() title = 'Input'
  @Input() id = 'input-id'
  @Input() value = false
  @Output() emitedValue = new EventEmitter<any>()


  constructor() { }

  ngOnInit() {
  }

  sendValue(event:any) {
    this.emitedValue.emit(!this.value)
  }

}
