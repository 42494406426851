import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TenantConfiguration } from './shared/models/tenantConfiguration.interface';
import { InitService } from './services/init.service';
import { TENANT } from './shared/models/tenant.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  showScan = false
  showMenu: boolean = false;
  tenantConfiguration!: TenantConfiguration;

  constructor(private router: Router, private initService: InitService) {
    router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          if (router.url.includes('init')) {
            this.showScan = true
            this.showMenu = true
          } else {
            this.showScan = false
          }
        }
      }
    )
  }

  ngOnInit(): void {
    this.checkUrl();
    this.initializeApp();
  }

  checkUrl() {
    const urlProductUrl = window.location.href

    if (urlProductUrl.includes('reserveRewardId')) {
      const splitedCode = urlProductUrl.split('reserveRewardId=')

      sessionStorage.setItem('reserveRewardId', splitedCode[1])
    }
    this.redirect()
  }

  initializeApp() {
    this.setTheme(this.initService.tenantConfigurations);
  }

  setTheme(tenantConfiguration: TenantConfiguration) {
    document.documentElement.style.setProperty('--color-primary', tenantConfiguration.primaryColor);
    document.documentElement.style.setProperty('--color-secondary', tenantConfiguration.secondaryColor);
    document.documentElement.style.setProperty('--color-tertiary', tenantConfiguration.tertiaryColor);
    document.documentElement.style.setProperty('--color-button-text', tenantConfiguration.buttonTextColor);

    if (tenantConfiguration.imageUrl !== '')
      document.documentElement.style.setProperty('--image-url', `url('${tenantConfiguration.imageUrl}')`);

    if (tenantConfiguration.iconUrl !== '')
      document.documentElement.style.setProperty('--icon-url', `${tenantConfiguration.iconUrl}`);

    this.tenantConfiguration = tenantConfiguration;

    TENANT.ID = this.tenantConfiguration.tenantId;
    TENANT.TENANT_NAME = this.tenantConfiguration.name;
    console.log(this.tenantConfiguration.iconUrl)
  }

  redirect() {
    if (!sessionStorage['session']) {
      this.router.navigateByUrl('login')
    } else {
      this.router.navigateByUrl('init')
    }
  }

  goToScan() {
    this.router.navigateByUrl('/scan-page')
  }
}
