export class MenuItems {
    menuModel(
        selectedPath:string,
        selectedIcon: string,
        selectedActiveIcon: string,
        selectadLabel: string,
        selectedEvent: string,
        selectedStatus: boolean
    ) {
        return {
            path: selectedPath,
            icon: selectedIcon,
            activeIcon: selectedActiveIcon,
            label: selectadLabel,
            event: selectedEvent,
            status: selectedStatus
        }
    }
}