import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Highlight } from '../../../models/highlight';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {

  @Input() highlight?: any
  @Input() pathToGo?: any

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  goTo() {
    this.router.navigateByUrl(this.pathToGo)
  }

}
