<div class="stepper-wrapper">
    <div class="stepper-item" [ngClass]="{ completed: activeStep >= 1 }">
        <div class="step-counter" [ngClass]="{ next: isNextStep(1) }">1</div>
        <div class="step-name f-16px f-neutral-3">{{ nameStep[0] }}</div>
    </div>
    <div class="stepper-item" [ngClass]="{ completed: activeStep >= 2 }">
        <div class="step-counter" [ngClass]="{ next: isNextStep(2) }">2</div>
        <div class="step-name f-16px f-neutral-3">{{ nameStep[1] }}</div>
    </div>
    <div class="stepper-item" [ngClass]="{ completed: activeStep >= 3 }">
        <div class="step-counter" [ngClass]="{ next: isNextStep(3) }">3</div>
        <div class="step-name f-16px f-neutral-3">{{ nameStep[2] }}</div>
    </div>
</div>