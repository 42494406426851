import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-line',
  templateUrl: './detail-line.component.html',
  styleUrls: ['./detail-line.component.scss']
})
export class DetailLineComponent implements OnInit {

  @Input() line?:any

  constructor() { }

  ngOnInit() {
  }

}
