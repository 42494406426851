<div class="row footer p-3 justify-content-between">
  <div class="col-md-2 col-sm-12 mb-10px text-center">
    <button class=" btn-default-tertiary" (click)="goBackStep()" *ngIf="showBack" type="button">
      Voltar
    </button>
  </div>
  <div class="col-md-2 col-sm-12mb-10px text-center">
    <button class=" btn btn-default-primary" (click)="goNextStep()" [disabled]="disabled" type="button">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
      Continuar
    </button>
  </div>
</div>