import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Category } from '../../../models/caregory';

@Component({
  selector: 'app-product-box-list',
  templateUrl: './product-box-list.component.html',
  styleUrls: ['./product-box-list.component.scss']
})
export class ProductBoxListComponent implements OnInit {

  @Input() category:any;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  goTo(route:any) {
    this.router.navigateByUrl(route)
  }
}
