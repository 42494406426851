<div class="row pt-24px pl-16px pr-16px">
  <div class="col left">
    <strong class="f-20px">{{category.name}}</strong>
  </div>
  <div class="col right" *ngIf="category.id !== 'idDeBusca'">
    <strong class="f-12px f-purple text-uppercase" (click)="goTo('dashboard/products/' + category.id)">Ver mais</strong>
  </div>
</div>
<div class="limit-x-scroll">
  <div class="scrollable-col center" *ngFor="let product of category.products">
    <div class="product-box" (click)="goTo('dashboard/product-detail/' + product.id)">
      <div class="top-sect" [ngStyle]="{'background-image': 'url(' + product?.image + ')'}"></div>
      <div class="bottom-sect left h-50per">
        <div class="row align-items-center mh-52px">
          <div class="col left">
            <span class="f-18px break-line">{{product.name}}</span>
          </div>
        </div>
        <div class="row pt-8px">
          <div class="col-10">
            <div class="cost-sect">
              <span class="f-purple f-14px">
                {{product.score}} pontos
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>