import { Place } from "./place";

export class Product {
    id!: string;
    title?: string;
    description?: string;
    externalLink?: string;
    type?: ProductType;
    cost: number = 0;
    image?: string;
    path?: string;
    status?: boolean;
    places: Place[] = [];
    
    [key: string]: any;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export enum ProductType {
    VIRTUAL = 'VIRTUAL',
    NON_VIRTUAL = 'NON_VIRTUAL'
}