import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'soma-close-back-bar',
  templateUrl: './close-back-bar.component.html',
  styleUrls: ['./close-back-bar.component.scss']
})
export class CloseBackBarComponent {

  @Input() showleftArrow:boolean = false
  @Input() showcross:boolean = false

  @Input() leftArrowPath:string = '';
  @Input() crossPath:string = '';

  @Output() evento = new EventEmitter<any>()

  constructor(
    private router: Router
  ) { }

  goTo(route:string) {
    if(route !== 'event') {
      this.router.navigateByUrl(route || 'start-soma')
    } else {
      this.evento.emit(route)
    }
  }

}
