import { Goal } from "./goal";

export class User {
    name!: string
    lastName!: string
    email!: string
    cpf!: string
    balance!: number
    avatar!: string
    goal: any = new Goal();

    [key: string]: any;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}