import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unityTypePipe'
})
export class UnityTypePipe implements PipeTransform {

  transform(value: any): any {
    if(value == 0) {
      return 'KG.'
    }
    if(value == 1) {
      return 'LT.'
    }
    if(value == 2) {
      return 'UNI.'
    }
    if(value == 3) {
      return 'MT.'
    }
  }

}
