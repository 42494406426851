<nav class="navbar navbar-expand-lg bg-color bg-border fixed-top">
  <div class="container p-1">
    <a class="navbar-brand f-16px" routerLink="init" routerLinkActive="active">
      <img [src]="iconUrl" /></a>
    <div class="sidebar navbar" tabindex="-1">
      <div class="navbar-body">
        <div class="nav-item">
          <span class="pointer-section f-neutral-4" (click)="logout()"><i
              class="bi bi-box-arrow-left pr-6px"></i>Sair</span>
        </div>
      </div>
    </div>
  </div>
</nav>