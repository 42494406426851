import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'soma-verification-field',
  templateUrl: './verification-field.component.html',
  styleUrls: ['./verification-field.component.scss']
})
export class VerificationFieldComponent {

  @Output() value = new EventEmitter<any>();
  @Input() isPhoneToken = false
  @Input() limitChar = 4  
  @Input() mask = '0000'


  verifyInput = ''

  checkForm() {
    const isValid = this.verifyInput.length >= this.limitChar || false

    if (isValid) {
      this.value.emit(this.verifyInput)
    }
  }
}
