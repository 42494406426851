import { Routes } from '@angular/router';

export const baseRoutes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: 'login',
        loadChildren: () =>
            import('../../pages/login-page/login-page.module').then(
                (m) => m.LoginPageModule
            ),
    },
    {
        path: 'init',
        loadChildren: () =>
            import('../../pages/init-page/init-page.module').then(
                (m) => m.InitPageModule
            ),
    },
    {
        path: 'scan-page',
        loadChildren: () => import('../../pages/scan-page/scan-page.module').then((m) => m.ScanPageModule)
    },
    {
        path: 'scan-page/:reserveRewardId/:step',
        loadChildren: () => import('../../pages/scan-page/scan-page.module').then((m) => m.ScanPageModule)
    }
] 