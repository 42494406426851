import { Product, ProductType } from "./product";

export class Goal {
    name?: string;
    percentage?: any;
    productInformation?: Product;

    [key: string]: any;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}