import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Output() nextStep = new EventEmitter<void>();
  @Output() backStep = new EventEmitter<void>();
  @Output() clearFields = new EventEmitter<void>();
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showBack: boolean = true;
  @Input() nextStepText: string = 'Continuar';

  goNextStep() {
    this.nextStep.emit();
  }

  goBackStep() {
    this.backStep.emit();
  }

}
