import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-bottom-modal',
  templateUrl: './bottom-modal.component.html',
  styleUrls: ['./bottom-modal.component.scss']
})
export class BottomModalComponent {

  @Output() closed = new EventEmitter<any>();

  mouseInDiv:boolean = false

  close(evt:any) {
    if(!this.mouseInDiv || evt) {
      this.closed.emit()
    } 
  }
}
